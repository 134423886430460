import React from 'react'
import ReactDOM from 'react-dom/client'
import { useCallback } from 'react'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'
import MyForm from './myForm'
import { particlesOptions } from './particlesOption'

import './style.css'

const App = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine)
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container)
  }, [])
  return (
    <div>
      <Particles
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesOptions}
      />
      <div id='container'>
        <img
          className='image_full responsive'
          src='./images/desktop.png'
          alt='Appblocks X Hacktoberfest'
        />
        <img
          className='image_mobile responsive'
          src='./images/mobile.png'
          alt='Appblocks X Hacktoberfest'
        />
        <div className='formabc'>
          <MyForm />
        </div>
      </div>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
