export const formData = [
  {
    tag: 'input',
    type: 'radio',
    name: 'intro',
    'cf-questions':
      'Hi there! 👋\nWelcome to Appblocks.\nHelp us get to know you better.',
    'cf-label': "Yes, Let's begin!🚀",
    'cf-input-placeholder': "Lets's begin!",
  },
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    required: true,
    'cf-questions': 'What is your name?',
    'cf-input-placeholder': 'Type here...',
  },
  {
    tag: 'input',
    type: 'email',
    name: 'email',
    required: true,
    'cf-error': 'Please provide a valid email address',
    'cf-questions': 'What is your email?',
    'cf-input-placeholder': 'Type here...',
  },
  {
    tag: 'input',
    type: 'text',
    name: 'country',
    required: true,
    'cf-questions': 'Please enter your country',
    'cf-input-placeholder': 'Type here...',
  },
  {
    tag: 'input',
    type: 'text',
    name: 'github',
    required: true,
    'cf-questions': 'Please enter your Github Profile',
    'cf-input-placeholder': 'Type here...',
  },
  {
    tag: 'input',
    type: 'radio',
    name: 'end',
    'cf-questions':
      'We are sending out some awesome swags😋.The top 20 devs🧑‍💻 with the highest score (1 quality PR = 1 POINT⭐) will win these.👕T-Shirts👕 and 😁Stickers😁 for other 🎖️top contributors as well🤩.\nPlease submit the form to enter the contest👍. ',
    'cf-label': 'Submit✅',
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'firstname',
  //   'cf-questions': 'What is your first name?',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'lastname',
  //   'cf-questions': 'What is your last name?',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'phoneNumber',
  //   'cf-questions': 'What is your contact number?',
  // },
  // {
  //   tag: 'input',
  //   type: 'email',
  //   name: 'email',
  //   'cf-questions': 'What is your email?',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'company',
  //   'cf-questions': 'What is the name of your company?',
  // },
  // {
  //   tag: 'input',
  //   type: 'radio',
  //   name: 'intro_2',
  //   'cf-questions':
  //     "Let's send you some awesome swags.\nThe top 20 dev with the highest score (1 quality PR = 1 POINT) will win awesome swags.\nTshirts and stickers for other top contributors as well.",
  //   'cf-label': 'Continue...',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'address-1',
  //   'cf-questions': 'Please enter your Address Line 1',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'address-2',
  //   'cf-questions': 'Please enter your Address Line 2',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'country',
  //   'cf-questions': 'Please enter your Country of residence',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'pincode',
  //   'cf-questions': 'PIN Code/ZIP Code',
  // },
  // {
  //   tag: 'fieldset',
  //   type: 'Radio buttons',
  //   required: 'required',
  //   'cf-questions': "Select your T-shirt Size\nIt's unisex😋",
  //   'cf-input-placeholder': 'Select your T-shirt Size',
  //   'cf-error': 'This field is required!',
  //   children: [
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'XS',
  //       value: 'XS',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'S',
  //       value: 'S',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'M',
  //       value: 'M',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'L',
  //       value: 'L',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'XL',
  //       value: 'XL',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': 'XXL',
  //       value: 'XXL',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': '3XL',
  //       value: '3XL',
  //     },
  //     {
  //       tag: 'input',
  //       type: 'radio',
  //       name: 'tshirtSize',
  //       'cf-label': '4XL',
  //       value: '4XL',
  //     },
  //   ],
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'linkedin',
  //   'cf-questions': 'Please enter your LinkedIn Profile',
  // },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'github',
  //   'cf-questions': 'Please enter your Github Profile',
  // },
  // {
  //   tag: 'input',
  //   type: 'radio',
  //   name: 'end',
  //   'cf-questions': 'Please submit the form to enter the contest. ',
  //   'cf-label': 'Submit',
  // },
]
