import React, { useEffect, useRef } from 'react'
import { ConversationalForm } from 'conversational-form'
import { formData } from './formbuilder'
import axios from 'axios'

const myForm = () => {
  const formRef = useRef()
  const url =
    'https://hacktoberfest-form-data.appblocks.com/api/form/create-json-log'
  const msg1 =
    "Thank you for becoming the newest member joining the DRY Days.\nKeep an eye out for our email updates! We'll be in touch soon."
  const msg2 =
    'Please feel free to share the event with your friends & colleagues, as our goal is to gather as many talents as possible.\n\nThanks again for being a part of our journey and for letting us be a part of yours.\n\nHappy Hacktober!'

  var conv_form

  useEffect(() => {
    conv_form = ConversationalForm.startTheConversation({
      options: {
        theme: 'dark',
        submitCallback,
        preventAutoFocus: true,
        showProgressBar: true,
      },
      tags: [...formData],
    })
    formRef.current.appendChild(conv_form.el)
  }, [])

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

  const submitCallback = async () => {
    var formDataSerialized = conv_form.getFormData(true)
    const { name, email, github, country } = formDataSerialized
    sendData({ name, email, github, country })
    conv_form.addRobotChatResponse(msg1)
    await sleep(5000)
    conv_form.addRobotChatResponse(msg2)
  }

  const sendData = (dataToSend) => {
    var data = JSON.stringify({
      metadata: dataToSend,
    })

    var config = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return <div ref={formRef}></div>
}

export default myForm
